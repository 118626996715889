import React, { useContext, useState, useEffect } from "react";
import { Link, graphql } from "gatsby";
import { FirebaseContext } from "../../components/Firebase";
import { sluggify } from "../../helpers/sluggify";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import ReactStars from 'react-rating-stars-component';

import Seo from "../seo";
import Header from "../organisms/Header";
import Navigator from "../organisms/Navigator";
import Copyright from "../atoms/Copyright";
import Loader from "../atoms/Loader";
import Footer from "../organisms/Footer";

const GuideInfo = (props) => {

  const { user } = useContext(FirebaseContext);

  const guide = props.data.guide,
        areas = guide.areas,
        generalInformation = props.data.allMarkdownRemark.edges;
  
  const [allSport, setAllSport] = useState(null);
  const [allTrad, setAllTrad] = useState(null);
  // const [allScramble, setAllScramble] = useState(null);

  useEffect(() => {
    if (areas) {

      let sportArr = [],
          tradArr = [];

      areas.forEach(area => {
        area.crags.forEach(crag => {
          crag.walls.forEach(wall => {
            
            wall.routes.filter((route) => route.isProject !== true && route.isTrad !== true && route.isScramble !== true).forEach((route) => {
              sportArr.push(route);
            });
            wall.routes.filter((route) => route.isProject !== true && route.isTrad === true && route.isScramble !== true).forEach((route) => {
              tradArr.push(route);
            });

          });
        });
      });

      setAllSport(sportArr);
      setAllTrad(tradArr);
      // setAllScramble(scrambleRoutes);

    }
  }, [areas]);

  const handleAccordionToggle = (e) => {
    let parent = e.target.parentElement,
        allItems = Array.from(e.target.parentNode.parentNode.childNodes),
        allSiblingsNotTarget= allItems.filter(element => element !== e.target.parentNode);
    
    if (parent.classList.contains('active')) {
      parent.classList.remove('active');
    } else {
      parent.classList.add('active');
      allSiblingsNotTarget.forEach(sibling => {
        if (sibling.classList.contains('active')) {
          sibling.classList.remove('active');
        };
      });
    }
  }

  return (
    <>
      {!user ? (
        <Loader />
      ) : (
        <>
          <Seo title={guide.name + ' Information'} />
          <Header color={"var(--black)"}>
            <Navigator guideName={guide.name} title={'About ' + guide.name} />
            {guide.name === 'Montagu' && user.montaguAccess && (
              <Link to={`/${sluggify(guide.name)}/search`} className="search-icon">
                <span>Search</span>
                <FontAwesomeIcon icon={ faSearch } size={ 'lg' } />
              </Link>
            )}
          </Header>
          <main>
            <div className="container">

              {generalInformation.length && (
                <ul className="info-list">
                  {generalInformation.sort((a, b) => a.node.frontmatter.order > b.node.frontmatter.order ? 1 : -1).map(content => (
                    <li key={content.node.id}>
                      <button className={content.node.frontmatter.title === "Emergencies and rescues" ? 'emergency' : ''} onClick={handleAccordionToggle}>{content.node.frontmatter.title}</button>
                      <div className="information" dangerouslySetInnerHTML={{ __html: content.node.html }} />
                    </li>
                  ))}
                </ul>
              )}

              <div className="information">
                <hr />
                <h3>Grade breakdown for {guide.name}</h3>
                <h5>Sport Routes</h5>
                {allSport && (
                  <table className="grade-breakdown">
                    <thead>
                      <tr>
                        <th>Grade</th>
                        <th>Total</th>
                        <th>
                          <ReactStars
                            value={5}
                            count={5}
                            color="var(--white)"
                            activeColor="var(--secondary)"
                            size={6}
                          />
                        </th>
                        <th>
                          <ReactStars
                            value={4}
                            count={4}
                            color="var(--white)"
                            activeColor="var(--secondary)"
                            size={6}
                          />
                        </th>
                        <th>
                          <ReactStars
                            value={3}
                            count={3}
                            color="var(--white)"
                            activeColor="var(--secondary)"
                            size={6}
                          />
                        </th>
                        <th>
                          <ReactStars
                            value={2}
                            count={2}
                            color="var(--white)"
                            activeColor="var(--secondary)"
                            size={6}
                          />
                        </th>
                        <th>
                          <ReactStars
                            value={1}
                            count={1}
                            color="var(--white)"
                            activeColor="var(--secondary)"
                            size={6}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>35/8c+</td>
                        <td>{allSport.filter((route) => route.grade === '35/8c+').length}</td>
                        <td>{allSport.filter((route) => route.grade === '35/8c+' && route.star === 5).length}</td>
                        <td>{allSport.filter((route) => route.grade === '35/8c+' && route.star === 4).length}</td>
                        <td>{allSport.filter((route) => route.grade === '35/8c+' && route.star === 3).length}</td>
                        <td>{allSport.filter((route) => route.grade === '35/8c+' && route.star === 2).length}</td>
                        <td>{allSport.filter((route) => route.grade === '35/8c+' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>34/8c</td>
                        <td>{allSport.filter((route) => route.grade === '34/8c').length}</td>
                        <td>{allSport.filter((route) => route.grade === '34/8c' && route.star === 5).length}</td>
                        <td>{allSport.filter((route) => route.grade === '34/8c' && route.star === 4).length}</td>
                        <td>{allSport.filter((route) => route.grade === '34/8c' && route.star === 3).length}</td>
                        <td>{allSport.filter((route) => route.grade === '34/8c' && route.star === 2).length}</td>
                        <td>{allSport.filter((route) => route.grade === '34/8c' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>33/8b+</td>
                        <td>{allSport.filter((route) => route.grade === '33/8b+').length}</td>
                        <td>{allSport.filter((route) => route.grade === '33/8b+' && route.star === 5).length}</td>
                        <td>{allSport.filter((route) => route.grade === '33/8b+' && route.star === 4).length}</td>
                        <td>{allSport.filter((route) => route.grade === '33/8b+' && route.star === 3).length}</td>
                        <td>{allSport.filter((route) => route.grade === '33/8b+' && route.star === 2).length}</td>
                        <td>{allSport.filter((route) => route.grade === '33/8b+' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>32/8b</td>
                        <td>{allSport.filter((route) => route.grade === '32/8b').length}</td>
                        <td>{allSport.filter((route) => route.grade === '32/8b' && route.star === 5).length}</td>
                        <td>{allSport.filter((route) => route.grade === '32/8b' && route.star === 4).length}</td>
                        <td>{allSport.filter((route) => route.grade === '32/8b' && route.star === 3).length}</td>
                        <td>{allSport.filter((route) => route.grade === '32/8b' && route.star === 2).length}</td>
                        <td>{allSport.filter((route) => route.grade === '32/8b' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>31/8a+</td>
                        <td>{allSport.filter((route) => route.grade === '31/8a+').length}</td>
                        <td>{allSport.filter((route) => route.grade === '31/8a+' && route.star === 5).length}</td>
                        <td>{allSport.filter((route) => route.grade === '31/8a+' && route.star === 4).length}</td>
                        <td>{allSport.filter((route) => route.grade === '31/8a+' && route.star === 3).length}</td>
                        <td>{allSport.filter((route) => route.grade === '31/8a+' && route.star === 2).length}</td>
                        <td>{allSport.filter((route) => route.grade === '31/8a+' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>30/8a</td>
                        <td>{allSport.filter((route) => route.grade === '30/8a').length}</td>
                        <td>{allSport.filter((route) => route.grade === '30/8a' && route.star === 5).length}</td>
                        <td>{allSport.filter((route) => route.grade === '30/8a' && route.star === 4).length}</td>
                        <td>{allSport.filter((route) => route.grade === '30/8a' && route.star === 3).length}</td>
                        <td>{allSport.filter((route) => route.grade === '30/8a' && route.star === 2).length}</td>
                        <td>{allSport.filter((route) => route.grade === '30/8a' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>29/7c+</td>
                        <td>{allSport.filter((route) => route.grade === '29/7c+').length}</td>
                        <td>{allSport.filter((route) => route.grade === '29/7c+' && route.star === 5).length}</td>
                        <td>{allSport.filter((route) => route.grade === '29/7c+' && route.star === 4).length}</td>
                        <td>{allSport.filter((route) => route.grade === '29/7c+' && route.star === 3).length}</td>
                        <td>{allSport.filter((route) => route.grade === '29/7c+' && route.star === 2).length}</td>
                        <td>{allSport.filter((route) => route.grade === '29/7c+' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>28/7c</td>
                        <td>{allSport.filter((route) => route.grade === '28/7c').length}</td>
                        <td>{allSport.filter((route) => route.grade === '28/7c' && route.star === 5).length}</td>
                        <td>{allSport.filter((route) => route.grade === '28/7c' && route.star === 4).length}</td>
                        <td>{allSport.filter((route) => route.grade === '28/7c' && route.star === 3).length}</td>
                        <td>{allSport.filter((route) => route.grade === '28/7c' && route.star === 2).length}</td>
                        <td>{allSport.filter((route) => route.grade === '28/7c' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>27/7b+</td>
                        <td>{allSport.filter((route) => route.grade === '27/7b+').length}</td>
                        <td>{allSport.filter((route) => route.grade === '27/7b+' && route.star === 5).length}</td>
                        <td>{allSport.filter((route) => route.grade === '27/7b+' && route.star === 4).length}</td>
                        <td>{allSport.filter((route) => route.grade === '27/7b+' && route.star === 3).length}</td>
                        <td>{allSport.filter((route) => route.grade === '27/7b+' && route.star === 2).length}</td>
                        <td>{allSport.filter((route) => route.grade === '27/7b+' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>26/7b</td>
                        <td>{allSport.filter((route) => route.grade === '26/7b').length}</td>
                        <td>{allSport.filter((route) => route.grade === '26/7b' && route.star === 5).length}</td>
                        <td>{allSport.filter((route) => route.grade === '26/7b' && route.star === 4).length}</td>
                        <td>{allSport.filter((route) => route.grade === '26/7b' && route.star === 3).length}</td>
                        <td>{allSport.filter((route) => route.grade === '26/7b' && route.star === 2).length}</td>
                        <td>{allSport.filter((route) => route.grade === '26/7b' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>25/7a+</td>
                        <td>{allSport.filter((route) => route.grade === '25/7a+').length}</td>
                        <td>{allSport.filter((route) => route.grade === '25/7a+' && route.star === 5).length}</td>
                        <td>{allSport.filter((route) => route.grade === '25/7a+' && route.star === 4).length}</td>
                        <td>{allSport.filter((route) => route.grade === '25/7a+' && route.star === 3).length}</td>
                        <td>{allSport.filter((route) => route.grade === '25/7a+' && route.star === 2).length}</td>
                        <td>{allSport.filter((route) => route.grade === '25/7a+' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>24/7a</td>
                        <td>{allSport.filter((route) => route.grade === '24/7a').length}</td>
                        <td>{allSport.filter((route) => route.grade === '24/7a' && route.star === 5).length}</td>
                        <td>{allSport.filter((route) => route.grade === '24/7a' && route.star === 4).length}</td>
                        <td>{allSport.filter((route) => route.grade === '24/7a' && route.star === 3).length}</td>
                        <td>{allSport.filter((route) => route.grade === '24/7a' && route.star === 2).length}</td>
                        <td>{allSport.filter((route) => route.grade === '24/7a' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>23/6c+</td>
                        <td>{allSport.filter((route) => route.grade === '23/6c+').length}</td>
                        <td>{allSport.filter((route) => route.grade === '23/6c+' && route.star === 5).length}</td>
                        <td>{allSport.filter((route) => route.grade === '23/6c+' && route.star === 4).length}</td>
                        <td>{allSport.filter((route) => route.grade === '23/6c+' && route.star === 3).length}</td>
                        <td>{allSport.filter((route) => route.grade === '23/6c+' && route.star === 2).length}</td>
                        <td>{allSport.filter((route) => route.grade === '23/6c+' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>22/6c</td>
                        <td>{allSport.filter((route) => route.grade === '22/6c').length}</td>
                        <td>{allSport.filter((route) => route.grade === '22/6c' && route.star === 5).length}</td>
                        <td>{allSport.filter((route) => route.grade === '22/6c' && route.star === 4).length}</td>
                        <td>{allSport.filter((route) => route.grade === '22/6c' && route.star === 3).length}</td>
                        <td>{allSport.filter((route) => route.grade === '22/6c' && route.star === 2).length}</td>
                        <td>{allSport.filter((route) => route.grade === '22/6c' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>21/6b+</td>
                        <td>{allSport.filter((route) => route.grade === '21/6b+').length}</td>
                        <td>{allSport.filter((route) => route.grade === '21/6b+' && route.star === 5).length}</td>
                        <td>{allSport.filter((route) => route.grade === '21/6b+' && route.star === 4).length}</td>
                        <td>{allSport.filter((route) => route.grade === '21/6b+' && route.star === 3).length}</td>
                        <td>{allSport.filter((route) => route.grade === '21/6b+' && route.star === 2).length}</td>
                        <td>{allSport.filter((route) => route.grade === '21/6b+' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>20/6b</td>
                        <td>{allSport.filter((route) => route.grade === '20/6b').length}</td>
                        <td>{allSport.filter((route) => route.grade === '20/6b' && route.star === 5).length}</td>
                        <td>{allSport.filter((route) => route.grade === '20/6b' && route.star === 4).length}</td>
                        <td>{allSport.filter((route) => route.grade === '20/6b' && route.star === 3).length}</td>
                        <td>{allSport.filter((route) => route.grade === '20/6b' && route.star === 2).length}</td>
                        <td>{allSport.filter((route) => route.grade === '20/6b' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>19/6a+</td>
                        <td>{allSport.filter((route) => route.grade === '19/6a+').length}</td>
                        <td>{allSport.filter((route) => route.grade === '19/6a+' && route.star === 5).length}</td>
                        <td>{allSport.filter((route) => route.grade === '19/6a+' && route.star === 4).length}</td>
                        <td>{allSport.filter((route) => route.grade === '19/6a+' && route.star === 3).length}</td>
                        <td>{allSport.filter((route) => route.grade === '19/6a+' && route.star === 2).length}</td>
                        <td>{allSport.filter((route) => route.grade === '19/6a+' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>18/6a</td>
                        <td>{allSport.filter((route) => route.grade === '18/6a').length}</td>
                        <td>{allSport.filter((route) => route.grade === '18/6a' && route.star === 5).length}</td>
                        <td>{allSport.filter((route) => route.grade === '18/6a' && route.star === 4).length}</td>
                        <td>{allSport.filter((route) => route.grade === '18/6a' && route.star === 3).length}</td>
                        <td>{allSport.filter((route) => route.grade === '18/6a' && route.star === 2).length}</td>
                        <td>{allSport.filter((route) => route.grade === '18/6a' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>17/5c</td>
                        <td>{allSport.filter((route) => route.grade === '17/5c').length}</td>
                        <td>{allSport.filter((route) => route.grade === '17/5c' && route.star === 5).length}</td>
                        <td>{allSport.filter((route) => route.grade === '17/5c' && route.star === 4).length}</td>
                        <td>{allSport.filter((route) => route.grade === '17/5c' && route.star === 3).length}</td>
                        <td>{allSport.filter((route) => route.grade === '17/5c' && route.star === 2).length}</td>
                        <td>{allSport.filter((route) => route.grade === '17/5c' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>16/5b</td>
                        <td>{allSport.filter((route) => route.grade === '16/5b').length}</td>
                        <td>{allSport.filter((route) => route.grade === '16/5b' && route.star === 5).length}</td>
                        <td>{allSport.filter((route) => route.grade === '16/5b' && route.star === 4).length}</td>
                        <td>{allSport.filter((route) => route.grade === '16/5b' && route.star === 3).length}</td>
                        <td>{allSport.filter((route) => route.grade === '16/5b' && route.star === 2).length}</td>
                        <td>{allSport.filter((route) => route.grade === '16/5b' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>15/5a</td>
                        <td>{allSport.filter((route) => route.grade === '15/5a').length}</td>
                        <td>{allSport.filter((route) => route.grade === '15/5a' && route.star === 5).length}</td>
                        <td>{allSport.filter((route) => route.grade === '15/5a' && route.star === 4).length}</td>
                        <td>{allSport.filter((route) => route.grade === '15/5a' && route.star === 3).length}</td>
                        <td>{allSport.filter((route) => route.grade === '15/5a' && route.star === 2).length}</td>
                        <td>{allSport.filter((route) => route.grade === '15/5a' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>14/4c</td>
                        <td>{allSport.filter((route) => route.grade === '14/4c').length}</td>
                        <td>{allSport.filter((route) => route.grade === '14/4c' && route.star === 5).length}</td>
                        <td>{allSport.filter((route) => route.grade === '14/4c' && route.star === 4).length}</td>
                        <td>{allSport.filter((route) => route.grade === '14/4c' && route.star === 3).length}</td>
                        <td>{allSport.filter((route) => route.grade === '14/4c' && route.star === 2).length}</td>
                        <td>{allSport.filter((route) => route.grade === '14/4c' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>13/4b</td>
                        <td>{allSport.filter((route) => route.grade === '13/4b').length}</td>
                        <td>{allSport.filter((route) => route.grade === '13/4b' && route.star === 5).length}</td>
                        <td>{allSport.filter((route) => route.grade === '13/4b' && route.star === 4).length}</td>
                        <td>{allSport.filter((route) => route.grade === '13/4b' && route.star === 3).length}</td>
                        <td>{allSport.filter((route) => route.grade === '13/4b' && route.star === 2).length}</td>
                        <td>{allSport.filter((route) => route.grade === '13/4b' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>12/4a</td>
                        <td>{allSport.filter((route) => route.grade === '12/4a').length}</td>
                        <td>{allSport.filter((route) => route.grade === '12/4a' && route.star === 5).length}</td>
                        <td>{allSport.filter((route) => route.grade === '12/4a' && route.star === 4).length}</td>
                        <td>{allSport.filter((route) => route.grade === '12/4a' && route.star === 3).length}</td>
                        <td>{allSport.filter((route) => route.grade === '12/4a' && route.star === 2).length}</td>
                        <td>{allSport.filter((route) => route.grade === '12/4a' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>11/3+</td>
                        <td>{allSport.filter((route) => route.grade === '11/3+').length}</td>
                        <td>{allSport.filter((route) => route.grade === '11/3+' && route.star === 5).length}</td>
                        <td>{allSport.filter((route) => route.grade === '11/3+' && route.star === 4).length}</td>
                        <td>{allSport.filter((route) => route.grade === '11/3+' && route.star === 3).length}</td>
                        <td>{allSport.filter((route) => route.grade === '11/3+' && route.star === 2).length}</td>
                        <td>{allSport.filter((route) => route.grade === '11/3+' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>10/3</td>
                        <td>{allSport.filter((route) => route.grade === '10/3').length}</td>
                        <td>{allSport.filter((route) => route.grade === '10/3' && route.star === 5).length}</td>
                        <td>{allSport.filter((route) => route.grade === '10/3' && route.star === 4).length}</td>
                        <td>{allSport.filter((route) => route.grade === '10/3' && route.star === 3).length}</td>
                        <td>{allSport.filter((route) => route.grade === '10/3' && route.star === 2).length}</td>
                        <td>{allSport.filter((route) => route.grade === '10/3' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>9/3-</td>
                        <td>{allSport.filter((route) => route.grade === '9/3-').length}</td>
                        <td>{allSport.filter((route) => route.grade === '9/3-' && route.star === 5).length}</td>
                        <td>{allSport.filter((route) => route.grade === '9/3-' && route.star === 4).length}</td>
                        <td>{allSport.filter((route) => route.grade === '9/3-' && route.star === 3).length}</td>
                        <td>{allSport.filter((route) => route.grade === '9/3-' && route.star === 2).length}</td>
                        <td>{allSport.filter((route) => route.grade === '9/3-' && route.star === 1).length}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>Total</td>
                        <td>{allSport.length}</td>
                        <td>{allSport.filter((route) => route.star === 5).length}</td>
                        <td>{allSport.filter((route) => route.star === 4).length}</td>
                        <td>{allSport.filter((route) => route.star === 3).length}</td>
                        <td>{allSport.filter((route) => route.star === 2).length}</td>
                        <td>{allSport.filter((route) => route.star === 1).length}</td>
                      </tr>
                    </tfoot>
                  </table>
                )}
                <h5>Trad Routes</h5>
                {allTrad && (
                  <table className="grade-breakdown">
                    <thead>
                      <tr>
                        <th>Grade</th>
                        <th>Total</th>
                        <th>
                          <ReactStars
                            value={5}
                            count={5}
                            color="var(--white)"
                            activeColor="var(--secondary)"
                            size={6}
                          />
                        </th>
                        <th>
                          <ReactStars
                            value={4}
                            count={4}
                            color="var(--white)"
                            activeColor="var(--secondary)"
                            size={6}
                          />
                        </th>
                        <th>
                          <ReactStars
                            value={3}
                            count={3}
                            color="var(--white)"
                            activeColor="var(--secondary)"
                            size={6}
                          />
                        </th>
                        <th>
                          <ReactStars
                            value={2}
                            count={2}
                            color="var(--white)"
                            activeColor="var(--secondary)"
                            size={6}
                          />
                        </th>
                        <th>
                          <ReactStars
                            value={1}
                            count={1}
                            color="var(--white)"
                            activeColor="var(--secondary)"
                            size={6}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>28</td>
                        <td>{allTrad.filter((route) => route.grade === '28').length}</td>
                        <td>{allTrad.filter((route) => route.grade === '28' && route.star === 5).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '28' && route.star === 4).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '28' && route.star === 3).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '28' && route.star === 2).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '28' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>27</td>
                        <td>{allTrad.filter((route) => route.grade === '27').length}</td>
                        <td>{allTrad.filter((route) => route.grade === '27' && route.star === 5).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '27' && route.star === 4).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '27' && route.star === 3).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '27' && route.star === 2).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '27' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>26</td>
                        <td>{allTrad.filter((route) => route.grade === '26').length}</td>
                        <td>{allTrad.filter((route) => route.grade === '26' && route.star === 5).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '26' && route.star === 4).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '26' && route.star === 3).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '26' && route.star === 2).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '26' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>25</td>
                        <td>{allTrad.filter((route) => route.grade === '25').length}</td>
                        <td>{allTrad.filter((route) => route.grade === '25' && route.star === 5).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '25' && route.star === 4).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '25' && route.star === 3).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '25' && route.star === 2).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '25' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>24</td>
                        <td>{allTrad.filter((route) => route.grade === '24').length}</td>
                        <td>{allTrad.filter((route) => route.grade === '24' && route.star === 5).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '24' && route.star === 4).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '24' && route.star === 3).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '24' && route.star === 2).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '24' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>23</td>
                        <td>{allTrad.filter((route) => route.grade === '23').length}</td>
                        <td>{allTrad.filter((route) => route.grade === '23' && route.star === 5).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '23' && route.star === 4).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '23' && route.star === 3).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '23' && route.star === 2).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '23' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>22</td>
                        <td>{allTrad.filter((route) => route.grade === '22').length}</td>
                        <td>{allTrad.filter((route) => route.grade === '22' && route.star === 5).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '22' && route.star === 4).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '22' && route.star === 3).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '22' && route.star === 2).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '22' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>21</td>
                        <td>{allTrad.filter((route) => route.grade === '21').length}</td>
                        <td>{allTrad.filter((route) => route.grade === '21' && route.star === 5).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '21' && route.star === 4).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '21' && route.star === 3).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '21' && route.star === 2).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '21' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>20</td>
                        <td>{allTrad.filter((route) => route.grade === '20').length}</td>
                        <td>{allTrad.filter((route) => route.grade === '20' && route.star === 5).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '20' && route.star === 4).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '20' && route.star === 3).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '20' && route.star === 2).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '20' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>19</td>
                        <td>{allTrad.filter((route) => route.grade === '19').length}</td>
                        <td>{allTrad.filter((route) => route.grade === '19' && route.star === 5).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '19' && route.star === 4).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '19' && route.star === 3).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '19' && route.star === 2).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '19' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>18</td>
                        <td>{allTrad.filter((route) => route.grade === '18').length}</td>
                        <td>{allTrad.filter((route) => route.grade === '18' && route.star === 5).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '18' && route.star === 4).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '18' && route.star === 3).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '18' && route.star === 2).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '18' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>17</td>
                        <td>{allTrad.filter((route) => route.grade === '17').length}</td>
                        <td>{allTrad.filter((route) => route.grade === '17' && route.star === 5).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '17' && route.star === 4).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '17' && route.star === 3).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '17' && route.star === 2).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '17' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>16</td>
                        <td>{allTrad.filter((route) => route.grade === '16').length}</td>
                        <td>{allTrad.filter((route) => route.grade === '16' && route.star === 5).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '16' && route.star === 4).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '16' && route.star === 3).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '16' && route.star === 2).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '16' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>15</td>
                        <td>{allTrad.filter((route) => route.grade === '15').length}</td>
                        <td>{allTrad.filter((route) => route.grade === '15' && route.star === 5).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '15' && route.star === 4).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '15' && route.star === 3).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '15' && route.star === 2).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '15' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>14</td>
                        <td>{allTrad.filter((route) => route.grade === '14').length}</td>
                        <td>{allTrad.filter((route) => route.grade === '14' && route.star === 5).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '14' && route.star === 4).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '14' && route.star === 3).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '14' && route.star === 2).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '14' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>13</td>
                        <td>{allTrad.filter((route) => route.grade === '13').length}</td>
                        <td>{allTrad.filter((route) => route.grade === '13' && route.star === 5).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '13' && route.star === 4).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '13' && route.star === 3).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '13' && route.star === 2).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '13' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>12</td>
                        <td>{allTrad.filter((route) => route.grade === '12').length}</td>
                        <td>{allTrad.filter((route) => route.grade === '12' && route.star === 5).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '12' && route.star === 4).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '12' && route.star === 3).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '12' && route.star === 2).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '12' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>11</td>
                        <td>{allTrad.filter((route) => route.grade === '11').length}</td>
                        <td>{allTrad.filter((route) => route.grade === '11' && route.star === 5).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '11' && route.star === 4).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '11' && route.star === 3).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '11' && route.star === 2).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '11' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>10</td>
                        <td>{allTrad.filter((route) => route.grade === '10').length}</td>
                        <td>{allTrad.filter((route) => route.grade === '10' && route.star === 5).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '10' && route.star === 4).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '10' && route.star === 3).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '10' && route.star === 2).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '10' && route.star === 1).length}</td>
                      </tr>
                      <tr>
                        <td>9</td>
                        <td>{allTrad.filter((route) => route.grade === '9').length}</td>
                        <td>{allTrad.filter((route) => route.grade === '9' && route.star === 5).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '9' && route.star === 4).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '9' && route.star === 3).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '9' && route.star === 2).length}</td>
                        <td>{allTrad.filter((route) => route.grade === '9' && route.star === 1).length}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>Total</td>
                        <td>{allTrad.length}</td>
                        <td>{allTrad.filter((route) => route.star === 5).length}</td>
                        <td>{allTrad.filter((route) => route.star === 4).length}</td>
                        <td>{allTrad.filter((route) => route.star === 3).length}</td>
                        <td>{allTrad.filter((route) => route.star === 2).length}</td>
                        <td>{allTrad.filter((route) => route.star === 1).length}</td>
                      </tr>
                    </tfoot>
                  </table>
                )}
              </div>
              
              <Copyright />
            </div>
          </main>
          <Footer />
        </>
      )}
    </>
  )
}

export const query = graphql`
  query GuideInfoQuery($guideId: String!) {
    guide(id: {eq: $guideId}) {
      name
      areas {
        id
        name
        color
        crags {
          walls {
            routes {
              isTrad
              isProject
              isScramble
              grade
              name
              star
            }
          }
        }
      }
    }
    allMarkdownRemark(filter: {frontmatter: {relation: {eq: $guideId}}}) {
      edges {
        node {
          id
          frontmatter {
            title
            order
          }
          html
        }
      }
    }
  }
`;

export default GuideInfo;